// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import config from "./config";
import { Container, Segment } from "semantic-ui-react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import "./App.css"
import Routes from "./Routes";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            authenticatedUserName: ''
        };
    }

    AuthenticatedUser = () => {
        return Auth.currentAuthenticatedUser();
    }

    async componentDidMount() {
        setTimeout(async () => {
            await this.AuthenticatedUser()
                .then((response) => {
                    const windowUrl = window.location.search;
                    const redirectIndex = windowUrl.indexOf("redirect=/") + 10;
                    this.setState({
                        isAuthenticated: true,
                        // Format: AmazonInternalUsers_alias - check to catch error when attribute missing
                        authenticatedUserName: 
                            response.attributes && response.attributes.email ? 
                                response.attributes.email : 
                                response.username.split("_")[1] + "@amazon.com"
                    })
                    this.props.history.push(windowUrl.substring(redirectIndex));
                })
                .catch(async (err) => {
                    if (err === "Access denied") {
                        this.props.history.push("/unauthorized");
                    }
                    await this.login();
                })
        }, 2000);
    }

    userHasAuthenticated = username => {
        const isAuthenticated = !!username;

        this.setState({
            isAuthenticated: isAuthenticated,
            authenticatedUserName: username
        });
    };

    login = () => {
        return Auth.federatedSignIn({ customProvider: "AmazonInternalUsers" });
    };


    handleLogout = async () => {
        await Auth.signOut();
        this.userHasAuthenticated(false);
        this.props.history.push("/");
    };

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            authenticatedUserName: this.state.authenticatedUserName
        };

        return (
            <Container>
                <Segment padded>
                    <Header history={this.props.history}
                        loginName={this.state.authenticatedUserName || ''}
                        headerTitle={config.HEADER_TITLE}
                        headerLogoUrl={config.HEADER_LOGO}
                        onLogout={this.handleLogout} />
                </Segment>
                <Segment padded>
                    <Routes childProps={childProps} />
                </Segment>
                <Footer />
            </Container>
        );
    }

}

export default withRouter(App);
