// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./components/NotFound";
import Login from "./components/Login";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Unauthorized from "./components/Unauthorized";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import CreatePrototypePage from "./components/CreatePrototypePage";
import PrototypeQualityDashboard from "./components/PrototypeQualityDashboard";
import QualityFindingsStats from "./components/QualityFindingsStats";
import CommonQualityFindings from "./components/CommonQualityFindings";
import { LandingPage } from "./components/LandingPage";
import LatestQualityFindingsForRepo from "./components/LatestQualityFindingsForRepo";
import LatestQualityFindingsForRepoForCategory from "./components/LatestQualityFindingsForRepoForCategory";

const routes = ({ childProps }) =>
  <Switch>
    <AuthenticatedRoute path="/" exact component={LandingPage} props={childProps} />
    <AuthenticatedRoute path="/create_prototype" exact component={CreatePrototypePage} props={childProps}/>
    <AuthenticatedRoute path="/quality_dashboard" exact component={PrototypeQualityDashboard} props={childProps}/>
    <AuthenticatedRoute path="/quality_stats" exact component={QualityFindingsStats} props={childProps}/>
    <AuthenticatedRoute path="/latest_quality_findings_for_repo_for_category/:repositoryName/:categoryId" exact component={LatestQualityFindingsForRepoForCategory} props={childProps}/>
    <AuthenticatedRoute path="/common_quality_findings" exact component={CommonQualityFindings} props={childProps}/>
    <AuthenticatedRoute path="/latest_quality_findings_for_repo/:repositoryName" exact component={LatestQualityFindingsForRepo} props={childProps}/>
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
    <UnauthenticatedRoute path="/unauthorized" exact component={Unauthorized} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

export default routes;