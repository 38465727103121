// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react'
import { colorMap } from './ColorsMap';

export class DashboardItem extends Component {

    defaultColor = "black"
    severityBarHeight = 10;

    constructor(props) {
        super(props);
        this.buildStyle = this.buildStyle.bind(this);
        this.dateDiffInDays = this.dateDiffInDays.bind(this);
    }

    buildStyle(severityType, numOfFindings) {
        const width = Math.floor(100 * (numOfFindings / this.props.dashboardItem.totalNumOfFindings))
        const backgroundColor = (severityType in colorMap) ? colorMap[severityType] : this.defaultColor;
        return {
            backgroundColor: backgroundColor,
            width: width + "%",
            minHeight: this.severityBarHeight + "px",
            maxHeight: this.severityBarHeight + "px",
            float: "left"
        }
    }

    buildSeverityBar(self, severityStats) {
        let bar = []
        Object.keys(severityStats).forEach(function (severityType) {
            bar.push(<div
                key={severityType}
                title={severityType + " severity: " + severityStats[severityType] + " findings"}
                style={self.buildStyle(severityType, severityStats[severityType])} />)
        })
        bar.push(<br key="last-severity-element" style={{ clear: "left" }} />)
        return bar
    }

    dateDiffInDays(utcDateString) {
        const now = new Date() // local timezone
        now.setHours(0,0,0,0);
        const analysisDate = new Date(utcDateString.substring(0, 10) + "T00:00:00.000Z") // utc time zone to local time zone
        analysisDate.setHours(0,0,0,0);
        const diffTime = Math.abs(now - analysisDate);  // both local timezone - we're good
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays === 1) return 'today'
        if (diffDays === 2) return 'yesterday'
        return diffDays + ' days ago'
    }

    render() {
        const self = this;
        let { severityStats, totalNumOfFindings, result, ran_quality_analysis } = this.props.dashboardItem;
        const buttonSize = {
            width: "12em",
            height: "6em",
        }
        const subHeaderStyle = { 
            color: "#232e3f", 
            fontSize: "10px" 
        }
        const whiteBackgroundStyle = {
            backgroundColor: "rgba(0,0,0,0)"
        }
        const blackBorderStyle = {
            ...buttonSize, 
            boxShadow: "0 0 0 1px #879596"
        }
        const blackHeaderStyle = {
            color: "#232e3f", 
            fontWeight: "bold"
        }
        
        return (
            <div>
                {ran_quality_analysis && totalNumOfFindings > 0 &&
                    <Button fluid title="click to view findings details" size='medium' basic color='red' style={buttonSize}>
                        <span style={{fontWeight: "bold"}}>{totalNumOfFindings} Findings</span>
                        <p style={subHeaderStyle}>
                            {this.dateDiffInDays(this.props.dashboardItem.analysisDateTime)}
                        </p>
                        {this.buildSeverityBar(self, severityStats)}
                    </Button>
                }
                {ran_quality_analysis && totalNumOfFindings === 0 && result === "SUCCESS" &&
                    <Button fluid title="Congrats! No findings." size='medium' basic icon labelPosition='left' positive style={buttonSize}>
                        <Icon color='green' name='checkmark' size='large' style={whiteBackgroundStyle}/>
                        <span style={{color: "#00ad00", fontWeight: "bold"}}>No Findings</span>
                        <p style={subHeaderStyle}>
                            {this.dateDiffInDays(this.props.dashboardItem.analysisDateTime)}
                        </p>
                    </Button>
                }
                {ran_quality_analysis && totalNumOfFindings === 0 && result === "FAILED" &&
                    <Button fluid title="Error! Quality Check failed." size='medium' basic icon labelPosition='left' style={blackBorderStyle}>
                        <Icon color='black' name='x' size='large' style={whiteBackgroundStyle}/>
                        <span style={blackHeaderStyle}>Check Failed</span>
                        <p style={subHeaderStyle}>
                            {this.dateDiffInDays(this.props.dashboardItem.analysisDateTime)}
                        </p>
                    </Button>
                }
                {!ran_quality_analysis &&
                    <Button fluid title="No Runs Yet" size='medium' basic icon labelPosition='left' style={blackBorderStyle}>
                        <span style={blackHeaderStyle}>No Runs Yet</span>
                        <p style={subHeaderStyle}>
                            Push a change to run this quality check
                        </p>
                    </Button>
                }
            </div>
        )
    }
}

