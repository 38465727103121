// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, {Component} from "react";
import { Container, Grid, Button, Header, Divider, Segment, List, Image } from 'semantic-ui-react';
import QualityHeader from "../assets/quality-header.png";
import Announcement from "../assets/announcement.png";

export class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subtitle: "The Quality Assurance Portal is a web tool that performs a series of automated quality checks against prototype repositories and report found issues to developers for further analysis and correction. The main goal of the tool is to raise the quality bar for the prototypes we build for our beloved customers.",
            action: {
                header: "Get Started",
                button: {
                    dev: "Create Repository",
                    management: "View Dashboard"
                },
                text: {
                    dev: "Onboard your prototype code.",
                    management: "Observe prototype quality metrics."
                },
                state: "Developer",
            },
            links: [
                {
                    header: "How to use QAP",
                    links: [
                        {
                            text: "QAP Wiki",
                            href: "https://w.amazon.com/bin/view/BDSI_Solutions_Prototyping/QualityAssurancePortal/",
                        },
                        {
                            text: "Hosting Options",
                            href: "https://w.amazon.com/bin/view/BDSI_Solutions_Prototyping/QualityAssurancePortal/#HCodeRepositoryHostingOptionsDuringPrototypeDevelopment"
                        }
                    ]
                },
                {
                    header: "Additional Resources",
                    links: [
                        {
                            text: "Release Details",
                            href: "https://w.amazon.com/bin/view/BDSI_Solutions_Prototyping/QualityAssurancePortal/Releases",
                        },
                        {
                            text: "QAP Interest Email List",
                            href: "https://email-list.corp.amazon.com/email-list/expand-list/aws-quality-assurance-portal-interest",
                        },
                        {
                            text: "How can I propose a new quality check?",
                            href: "https://form.asana.com/?k=UMo-olEoKWZ9iA-5KXUmWA&d=8442528107068&rr=563284",
                        },
                        {
                            text: "Provide Feedback",
                            href: "https://form.asana.com/?k=iTcQ3yA1PkuX6qL3jA3O4g&d=8442528107068",
                        },
                        {
                            text: "Email Us",
                            href: "mailto:aws-quality-assurance-portal@amazon.com"
                        }
                    ]
                }
            ],
            directions: {
                header: "How it Works",
                text: [
                    "Create a code repository",
                    "Push changes to the repository",
                    "Receive automated code analysis results in your email",
                    "Address the applicable findings and continue from step 2",
                    "Review quality statistics and common findings across multiple repositories",
                    "Provide feedback and suggestions to improve the tool",
                    "Be Awesome! Create your own quality check and win a phone tool icon!"
                ],
            },
            benefits_features: {
                header: "Benefits & Features",
                text: [
                    "Repository Creation: create a code repository for your project following the expected naming convention and with automated code analysis enabled",
                    "Code Analysis: receive automated code analysis results in your email or through a rich web dashboard",
                    "Quality Statistics: check out statistics about the various code analysis categories across individual or multiple repositories",
                    "Common Findings: find out what the most common issues are across all repositories analyzed",
                    "Security Reviews:  know what issues to address in your code to pass the security review and have your code ready for your customers"
                ],
            }
        }
        this.handleActionChange = this.handleActionChange.bind(this);
    }

    renderDashboardContainer(content) {
        return (
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        {content}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    renderContentHeader(header) {
        return <Header as='h3' attached='top'>{header}</Header>;
    }

    renderListContent(content) {
        const { header, text } = content;

        return (
            <Grid.Column>
                {this.renderContentHeader(header)}
                <Segment attached>
                    <List ordered>
                        {text.map((value, index) => {
                            if (value.includes(":")) {
                                const splitValue = value.split(":");
                                return (
                                    <List.Item key={index}>
                                        <b>{splitValue[0]}</b>: {splitValue[1]}
                                    </List.Item>
                                );
                            } else {
                                return <List.Item key={index}>{value}</List.Item>;
                            }

                        })}
                    </List>
                </Segment>
            </Grid.Column>
        )
    }

    handleActionChange(e, { name }) {
        this.setState({
            action: {
                ...this.state.action,
                state: name,
            }
        })
    }

    handleClick = async(e, { name, link }) => {
        if (name) {
            let link = '/'
            link = link + name;
            this.props.history.push(link);
        }
        if (link) window.open(link);
    }

    renderActionContent(content) {
        const { header, button, text } = content;
        const developerState = this.state.action.state === "Developer";
        return (
            <Grid.Column>
                {this.renderContentHeader(header)}
                <Segment attached>
                    <Button.Group>
                        <Button positive={developerState} name="Developer" onClick={this.handleActionChange}>
                            Developer
                        </Button>
                        <Button.Or />
                        <Button positive={!developerState} name="Management"  onClick={this.handleActionChange}>
                            Management
                        </Button>
                    </Button.Group>
                    <p />
                    <Container>
                        {developerState ? text.dev : text.management}
                    </Container>
                    <p />
                    <Button
                        color="orange"
                        onClick={this.handleClick}
                        name={developerState ? "create_prototype" : "quality_dashboard"}
                    >
                        {developerState ? button.dev : button.management}
                    </Button>
                </Segment>
            </Grid.Column>
        );
    }

    renderLinkContent(content) {
        const { header, links } = content;
        return (
            <Grid.Column>
                {this.renderContentHeader(header)}
                <Segment attached>
                    <List>
                        {links.map((value, index) => {
                            const { text, href } = value;
                            return <List.Item as='a' key={index} onClick={this.handleClick} link={href}>{text}</List.Item>;
                        })}
                    </List>
                </Segment>
            </Grid.Column>
        );
    }

    renderLinkText(link, text) {
        return (
            <a target="_blank" href={link} rel="noopener noreferrer">
                {text}
            </a>
        );
    }

    renderAnnouncementContent(content) {
        return (
            <Container>
                {this.renderContentHeader("Announcements")}
                <Segment attached>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <Image src={Announcement} />
                            </Grid.Column>
                            <Grid.Column width={14}>
                                <List>
                                    <List.Item>
                                        <b>Version 1 of QAP is officially released!</b> New Features include:
                                        <List ordered>
                                            <List.Item>
                                                A plugin feature where you can contribute a new quality check into QAP. Submit a proposal {this.renderLinkText("https://form.asana.com/?k=UMo-olEoKWZ9iA-5KXUmWA&d=8442528107068&rr=563284", "here")}.
                                            </List.Item>
                                            <List.Item>
                                                A new quality check for verifying the {this.renderLinkText("https://w.amazon.com/bin/view/BDSI_Solutions_Prototyping/QualityAssurancePortal/AWSCopyrightHeader/", "AWS Copyright Header")} is present in your repository files.
                                            </List.Item>
                                            <List.Item>
                                                A new integration of {this.renderLinkText("https://github.com/cdklabs/cdk-nag", "CDK-Nag")} in the CloudFormation quality check.
                                            </List.Item>
                                            <List.Item>
                                                A new integration of the {this.renderLinkText("https://github.com/davglass/license-checker", "NPM License Checker")} to check for {this.renderLinkText("https://policy.amazon.com/standard/82477", "AWS prohibited open source licenses")} in the NPM quality check.
                                            </List.Item>
                                        </List>
                                    </List.Item>
                                    <List.Item>
                                        QAP will be presented at <b>Munich, Germany</b> at the {this.renderLinkText("https://w.amazon.com/bin/view/SPDOffsiteMunich/", "BDSI Prototyping offsite")} on Tuesday, October 4th.
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        )
    }

    render() {
        return (
            <Container>
                <Container>
                    <Image src={QualityHeader} />
                    <Header as='h1'>
                        <Header.Subheader>
                            {this.state.subtitle}
                        </Header.Subheader>
                    </Header>
                </Container>
                <Divider />
                {this.renderAnnouncementContent()}
                <Divider />
                <Grid columns={2} divided textAlign="left">
                    <Grid.Row>
                        <Grid.Column width={10}>
                            {this.renderDashboardContainer(this.renderListContent(this.state.directions))}
                            {this.renderDashboardContainer(this.renderListContent(this.state.benefits_features))}
                        </Grid.Column>
                        <Grid.Column width={6}>
                            {this.renderDashboardContainer(this.renderActionContent(this.state.action))}
                            {this.renderDashboardContainer(this.renderLinkContent(this.state.links[0]))}
                            {this.renderDashboardContainer(this.renderLinkContent(this.state.links[1]))}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}