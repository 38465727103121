// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from "react";
import { Header, Container, Segment, Message, Table, Icon } from 'semantic-ui-react';
import "./QualityFindingsPerCategoryView.css";

export default class QualityFindingsPerCategoryView extends Component {

  formatKeyValue(key, keyValue) {
    const branchName = this.props.data.branch_name;
    if (Array.isArray(keyValue)) {
      return keyValue.join(", ");
    } else if (key === "code_repository_name" && branchName) {
      return `${keyValue} (${branchName})`;
    } else {
      return keyValue;
    }
  }

  renderSourceDetails() {
    const { sources } = this.props.data;
    return (
      <Segment basic>
          <Header as="h4">Analysis Tool(s)</Header>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sources.map((key, index) => {
                const { name, description, link } = key;
                return (
                  <Table.Row key={index}>
                    <Table.Cell><a href={link} target="_blank" rel="noopener noreferrer">{name}</a></Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Segment>
    );
  }

  sortArtifacts(artifacts) {
    function compare(item1, item2) {
      const artifact_location1 = item1['artifact_location'].toLowerCase()
      const artifact_location2 = item2['artifact_location'].toLowerCase()
      if (artifact_location1 < artifact_location2) return -1;
      if (artifact_location1 > artifact_location2) return 1;
      const artifact_name1 = item1['artifact_name'].toLowerCase()
      const artifact_name2 = item2['artifact_name'].toLowerCase()
      if (artifact_name1 < artifact_name2) return -1;
      if (artifact_name1 > artifact_name2) return 1;
      return 0;
    }
    return artifacts.sort(compare);
  }

  sortArtifactFindings(findings) {
    const severityMap = {
      'critical': 0,
      'high': 1,
      'medium': 2,
      'low': 3,
      'unknown': 100
    };
    function compare(item1, item2) {
      const severity1 = severityMap[item1['severity'].toLowerCase()]
      const severity2 = severityMap[item2['severity'].toLowerCase()]
      if (severity1 < severity2) return -1;
      if (severity1 > severity2) return 1;
      const startLine1 = item1['line_numbers'].length > 0 ? parseInt(item1['line_numbers'][0]) : parseInt(item1['start_line']);
      const startLine2 = item2['line_numbers'].length > 0 ? parseInt(item2['line_numbers'][0]) : parseInt(item2['start_line']);
      if (startLine1 > startLine2) return 1;
      if (startLine2 > startLine1) return -1;
      return 0;
    }
    return findings.sort(compare);
  }

  renderErrorMessage() {
    const { result_msg } = this.props.data;
    return (
      <Segment basic>
        <Container>
          <Header as="h3" color="black">
            <Icon name="window close outline" />
            Error Message
          </Header>
        </Container>
        <Container>
          <Message>
            {result_msg}
          </Message>
        </Container>
      </Segment>
    )
  }

  renderQualityFindings() {
    const { findings } = this.props.data;
    return (
      <Segment basic>
        <Container>
          <Header as="h3" color="black">
            Quality Findings
          </Header>
          {
            this.sortArtifacts(findings).map((item, index) => {
              return (
                <div key={index}>
                  <Table celled key={index}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan={3}>
                          <div className='highlight'>{item['artifact_location']}{item['artifact_name']}</div>
                        </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell>Severity</Table.HeaderCell>
                        <Table.HeaderCell>Line Number(s)</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.sortArtifactFindings(item['artifact_findings']).map((value, index) => {
                        const { start_line, end_line, description, line_numbers } = value;
                        let { severity } = value;
                        severity = severity.toLowerCase();
                        let lines = ""
                        if (line_numbers.length > 0) {
                          lines = line_numbers.join(', ');
                        }
                        else if (start_line === -1) {
                          lines = "-"
                        } else if (end_line === -1 || end_line === start_line) {
                          lines = start_line;
                        } else {
                          lines = start_line + "-" + end_line;
                        }
                        return (
                          <Table.Row key={index}>
                            <Table.Cell className={severity}>{severity}</Table.Cell>
                            <Table.Cell>{lines}</Table.Cell>
                            <Table.Cell>{description}</Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                </div>
              )
            })
          }
        </Container>
      </Segment>
    )
  }

  renderSummary() {
    const {total_number_of_files_inspected, total_number_of_findings, review_duration_in_secs} = this.props.data;
    return (
      <Segment basic>
        <Header as="h4">Summary</Header>
        <Table celled>
            <Table.Body>
                <Table.Row key="total_number_of_findings">
                  <Table.Cell>Number of findings</Table.Cell>
                  <Table.Cell>{total_number_of_findings}</Table.Cell>
                </Table.Row>
                <Table.Row key="total_number_of_files_inspected">
                  <Table.Cell>Number of files inspected</Table.Cell>
                  <Table.Cell>{total_number_of_files_inspected}</Table.Cell>
                </Table.Row>
                <Table.Row key="review_duration_in_secs">
                  <Table.Cell>Analysis running time (secs)</Table.Cell>
                  <Table.Cell>{review_duration_in_secs}</Table.Cell>
                </Table.Row>
            </Table.Body>
          </Table>
      </Segment>
    )
  }

  renderHeader() {
    const { category } = this.props.data;
    return (<Container>
      <Header as='h2' color='blue' textAlign='left'>{category}</Header>
    </Container>)
  }

  render() {
    const { result, findings } = this.props.data;
    return (
      <Container>
        {this.renderHeader()}
        {this.renderSourceDetails()}
        {this.renderSummary()}
        {
          result === "FAILED" && 
          this.renderErrorMessage()
        }
        {
          findings.length > 0 && 
          this.renderQualityFindings()
        }
        {
          findings.length == 0 && 
          <Segment>
            <Header as="h4">No Findings!</Header>
          </Segment>
        }
      </Container>
    );
  }

}