// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from "react";
import { Header, Container, Segment, Message, Icon } from 'semantic-ui-react';
import { Loader } from "./Loader"
import { API } from "aws-amplify";
import QualityFindingsPerCategoryView from "./QualityFindingsPerCategoryView"
import PrototypeDetailsView from "./PrototypeDetailsView";
import ErrorMessageView from "./ErrorMessageView"
export default class LatestQualityFindingsForRepoForCategory extends Component {

  constructor(props) {
    super(props);
    this.repositoryName = this.props.match?.params?.repositoryName;
    this.categoryId = this.props.match?.params?.categoryId;
    this.state = {
      loading: true,
      errorFound: false,
      errorMessage: '',
      data: {}
    }
  }

  callAPI(apiName, apiPath, apiQueryString) {
    try {
      console.log(`Calling API: ${apiPath}?${apiQueryString}`)
      API.get(
        apiName,
        `${apiPath}?${apiQueryString}`
      ).then(response => {
        console.log(response)
        if (response === []) {
          const errorMessage = `Got an empty response for API call: ${apiPath}?${apiQueryString}`
          console.warn(errorMessage)
          this.setState({
            loading: false,
            errorFound: true,
            errorMessage: errorMessage
          })
        } else {
          this.setState({
            loading: false,
            data: response,
          })
        }
      })
      .catch(error => {
        const errorMessage = `Unexpected error: ${error}`
        console.error(errorMessage);
        this.setState({
          loading: false,
          errorFound: true,
          errorMessage: errorMessage
        })
      })
    } catch (error) {
      const errorMessage = `Unexpected error: ${error}`
      console.error(errorMessage);
      this.setState({
        loading: false,
        errorFound: true,
        errorMessage: errorMessage
      })
    }
  }

  componentDidMount() {
    this.callAPI(
      "PrototypeQualityAPI",
      '/report/quality_findings_for_repo_for_category',
      `repository_name=${this.repositoryName}&quality_check_type=${this.categoryId}`
    )
  }

  renderErrorMessage() {
    const { result_msg } = this.state.data;
    return (
      <Segment basic>
        <Container>
          <Header as="h3" color="black">
            <Icon name="window close outline" />
            Error Message
          </Header>
        </Container>
        <Container>
          <Message>
            {result_msg}
          </Message>
        </Container>
      </Segment>
    )
  }

  renderHeader() {
    return (
      <Container>
        <Header as="h1" color='black' textAlign='left'>
         Quality Findings Report
        </Header>
        <p>
          Generated on: {new Date().toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" })}
        </p>
      </Container>
    )
  }

  render() {
    const { data } = this.state;
    return (
      <Container>
        {
          this.state.loading &&
          <Loader message='“Quality is pride of workmanship.” ~W. Edwards Deming' />
        }
        {
          !this.state.loading && 
          !this.state.errorFound && 
          this.renderHeader()
        }
        { 
          !this.state.loading && 
          !this.state.errorFound && 
          <PrototypeDetailsView data={data} />
        }
        {
          !this.state.loading && 
          !this.state.errorFound && 
          <QualityFindingsPerCategoryView data={data} />
        }
        {
          this.state.errorFound &&
          <ErrorMessageView errorMessage={this.state.errorMessage} />
        }
      </Container>
    );
  }

}