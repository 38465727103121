// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from "react";
import { Header, Container, Segment, Accordion, Icon, Label, Divider } from 'semantic-ui-react';
import { API } from "aws-amplify";
import { Loader } from "./Loader"
import "./CommonQualityFindings.css";

export default class CommonQualityFindings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loading_error: false,
      qualityAnalysisData: null,
      activeL1Index: -1,
      activeL2Index: -1,
      activeL3Index: -1,
    }
    this.handleLevel1AccordionClick = this.handleLevel1AccordionClick.bind(this);
  }

  componentDidMount() {
    try {
      API.get(
        "PrototypeQualityAPI", "/get_common_quality_findings"
      ).then(data => {
        console.log(data)
        this.setState({
          qualityAnalysisData: data,
          loading: false,
          loading_error: false,
        })
      })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.error(error);
    }
  }

  handleLevel1AccordionClick = (e, titleProps) => {
    const newIndex = this.state.activeL1Index === titleProps.index ? -1 : titleProps.index
    this.setState(
      {
        activeL1Index: newIndex,
        activeL2Index: -1,
        activeL3Index: -1
      }
    )
  }

  handleLevel2AccordionClick = (e, titleProps) => {
    const newIndex = this.state.activeL2Index === titleProps.index ? -1 : titleProps.index
    this.setState(
      {
        activeL2Index: newIndex,
        activeL3Index: -1
      }
    )
  }

  handleLevel3AccordionClick = (e, titleProps) => {
    const newIndex = this.state.activeL3Index === titleProps.index ? -1 : titleProps.index
    this.setState({ activeL3Index: newIndex })
  }

  renderSourceCodeGroup(sourceLines, startLine, shouldHighlight) {
    const codeLines = []
    sourceLines.forEach((line, index) => {
      codeLines.push(
        <code key={index}>
          {shouldHighlight && <span className="highlighted_line">{startLine + index}: {line}</span>}
          {!shouldHighlight && <span>{startLine + index}: {line}</span>}
        </code>
      )
    })
    return codeLines;
  }

  renderGroupedFindingSourceCode(sourceLineGroups) {
    return (
      <Segment basic>
        <pre>
          {sourceLineGroups.map((sourceLineGroup, index) => {
            const lines = [
              this.renderSourceCodeGroup(sourceLineGroup.before_lines, sourceLineGroup.indexes[0], false),
              this.renderSourceCodeGroup(sourceLineGroup.actual_lines, sourceLineGroup.indexes[1], true),
              this.renderSourceCodeGroup(sourceLineGroup.after_lines, sourceLineGroup.indexes[2], false),
            ]
            return (
              <Segment basic key={"source-line-group-" + index}>{lines}
                <Divider />
              </Segment>)
          })}
        </pre>
      </Segment>
    )
  }

  renderGroupedFindingLocations(groupedFindingLocations) {
    const activeIndex = this.state.activeL3Index;
    return groupedFindingLocations.map((location, index) => (
      <Accordion
        key={'location-accordion-' + index}
        exclusive={false}
      >
        <Accordion.Title
          index={index}
          onClick={this.handleLevel3AccordionClick}
        >
          <Segment color='black'>
            <div className="artifact_path">
              {activeIndex === index && <Icon name='dropdown' />}
              <Label basic>
                <Icon name='file' />
                {location.artifact}
              </Label>
              <Label basic>
                <Icon name='database' />
                Repository: {location.code_repository}
              </Label>
            </div>
          </Segment>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === index}>
          <Segment raised>
            <Label basic pointing='below'>
              <Icon name='terminal' />
              {location.description}
            </Label>
            {this.renderGroupedFindingSourceCode(location.source_lines)}
          </Segment>
        </Accordion.Content>
      </Accordion>
    ));
  }
  renderGroupedFindings(grouped_findings) {
    const activeIndex = this.state.activeL2Index;
    
    return grouped_findings.map((grouped_finding, index) => (
      <Accordion
        key={'grouped-finding-accordion-' + index}
        exclusive={false}
      >
        <Accordion.Title
          index={index}
          onClick={this.handleLevel2AccordionClick}
        >
          <Segment secondary>
            <Header as="h5">
                ({index + 1}) {grouped_finding.description}
            </Header>
            <div>
              <Label basic>
                <span className={`${grouped_finding.severity}`}>
                  {grouped_finding.severity} severity
                </span>
              </Label>
              <Label basic>
                {grouped_finding.num_locations} occurrences across {grouped_finding.num_repositories} repositories
              </Label>
            </div>
          </Segment>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === index}>
          <Segment padded basic>
            {this.renderGroupedFindingLocations(grouped_finding.locations)}
          </Segment>
        </Accordion.Content>
      </Accordion>
    ));
  }

  renderQualityAnalysisCategoryResults(qualityAnalysisCategoryData) {
    return (
      <div>
        <Segment raised>
          <h5>Quality tool(s) used:</h5>
          {qualityAnalysisCategoryData.sources.map((source, index) => {
            return (
              <div key={'quality-tool-' + index}><a href="{source.link}" target="_blank">{source.name}</a> {source.description}</div>
            )
          })}
        </Segment>
        <Segment raised>
          <h4>Aggregated Findings:</h4>
          {this.renderGroupedFindings(qualityAnalysisCategoryData.grouped_findings)}
        </Segment>
      </div>
    )
  }

  renderQualityAnalysisResults() {
    const { qualityAnalysisData } = this.state;
    const activeIndex = this.state.activeL1Index
    return Object.keys(qualityAnalysisData).map((qualityAnalysisCategory, index) => (
      <Accordion
        key={'accordion-' + index}
        exclusive={false}
      >
        <Accordion.Title
          active={activeIndex === index}
          index={index}
          onClick={this.handleLevel1AccordionClick}
        >
          <div className="quality_category_title"><Icon name='dropdown' />{qualityAnalysisCategory}</div>
        </Accordion.Title>
        <Accordion.Content
          active={activeIndex === index}
        >
          <Segment padded basic>
            {this.renderQualityAnalysisCategoryResults(qualityAnalysisData[qualityAnalysisCategory])}
          </Segment>
        </Accordion.Content>
      </Accordion>
    ));
  }

  renderFetchedContent() {
    return (
      <Segment basic textAlign="left">
        <Header as="h2">Common Quality Findings</Header>
        <Segment basic>
          This report lists the most common quality analysis findings across several repositories.
          Click on a quality analysis category below to expand the report.
        </Segment>
        {this.renderQualityAnalysisResults()}
      </Segment>
    )
  }

  render() {
    return (
      <Container>
        {!this.state.loading && this.renderFetchedContent()}
        {this.state.loading &&
          <Loader message='"Every action is an opportunity to improve.” ~Mark Graban' />
        }
      </Container>
    );
  }
}
